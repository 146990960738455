import log from "loglevel";
import React, { useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import { PRODUCTS } from "./products";
import { CommonPage, ErrorBoundary, ScrollToTop } from "@components/common";
import { ProductIndependentPageRoutes } from "./product-independent-pages/routes";
import { FootnotesProvider } from "@features/footnotes";
import { isTestStage } from "@common/env";

import { applicationStart } from "@services/api/application";
import { getParam, isDev, isKeycloakUrl } from "@common/utils";
import { store } from "@services/redux";
import * as transactionActions from "@services/redux/features/transaction.redux";
import { ReactKeycloakProvider } from "@react-keycloak/web";
import { connect } from "react-redux";
import { getKeycloakIdp, getKeycloakInitialized, setKeycloakIdp, setKeycloakIdpHint, setKeycloakInitialized } from "@services/redux/features/keycloak.redux";
import keycloakClient from "./keycloak";
import { CustomRouter } from "@components/CustomRouter/CustomRouter";
import { createBrowserHistory } from "history";
import "./styles.css";
import { LoadingPage } from "@product-pages/pages";
import { initializeAnalytics } from "@services/analytics/functions";

log.setLevel(isTestStage() ? "DEBUG" : "ERROR", false);

interface AppProps {
  setKeycloakIdp: any;
  setKeycloakIdpHint: any;
  setKeycloakInitialized: any;
  keycloakInitialized: boolean;
}

export const history = createBrowserHistory();

const App = (props: AppProps) => {
  const { setKeycloakIdp, setKeycloakIdpHint, keycloakInitialized } = props;
  const [isKeycloakReady, setIsKeycloakReady] = React.useState(false);
  const [allReady, setAllReady] = React.useState(false);
  const [storefrontId] = React.useState(() => {
    const id = getParam(document, "id") || getParam(document, "cid") || getParam(document, "transactionId");
    // eslint-disable-next-line consistent-return
    return id;
  });
  const [token] = React.useState(() => {
    const t = getParam(document, "token");
    // eslint-disable-next-line consistent-return
    return t;
  });

  useEffect(() => {
    initializeAnalytics();
    if (token) {
      store.dispatch(transactionActions.setToken(token));
    }

    if (!storefrontId) {
      const doStart = async () => {
        store.dispatch(transactionActions.setSelfCreated(true));
        const { expiryDate } = await applicationStart();
        store.dispatch(transactionActions.saveExpiryDate(expiryDate));
      };
      doStart();
    } else {
      store.dispatch(transactionActions.setTransactionId(storefrontId));
    }
    setAllReady(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isKeycloakReady) {
      setKeycloakInitialized(true);
    }
  }, [isKeycloakReady]);

  const eventLogger = (event: unknown, error: unknown) => {
    if (isDev()) {
      console.log("onKeycloakEvent", event, error);
    }
    if (event === "onReady" || event === "onInitError") {
      setIsKeycloakReady(true);
    }
  };

  const tokenLogger = (tokens: unknown) => {
    if (isDev()) {
      console.log("onKeycloakTokens", tokens);
    }
  };

  const keycloak = keycloakClient.verimi.kc;
  setKeycloakIdp(keycloakClient.verimi.name);
  setKeycloakIdpHint(keycloakClient.verimi.idpHint);

  const Body = ({ renderCondition, spinner = false }: any) => (
    <div className="o-root">
      <CustomRouter history={history}>
        <ErrorBoundary>
          <FootnotesProvider>
            <ScrollToTop>
              {renderCondition ? (
                <React.Suspense fallback={spinner ? <LoadingPage /> : <CommonPage />}>
                  <Routes>
                    {PRODUCTS.map((product) => (
                      <Route key={product.productPath} path={`${product.productPath}/*`} element={<product.component />} />
                    ))}
                    <Route path="*" element={<ProductIndependentPageRoutes />} />
                  </Routes>
                </React.Suspense>
              ) : spinner ? (
                <LoadingPage />
              ) : (
                <CommonPage />
              )}
            </ScrollToTop>
          </FootnotesProvider>
        </ErrorBoundary>
      </CustomRouter>
    </div>
  );

  return !isKeycloakUrl() && !keycloakInitialized ? (
    <Body renderCondition={allReady} />
  ) : (
    <ReactKeycloakProvider
      authClient={keycloak}
      initOptions={{
        checkLoginIframe: false,
      }}
      onEvent={eventLogger}
      onTokens={tokenLogger}
    >
      <Body renderCondition={isKeycloakReady && allReady} spinner />
    </ReactKeycloakProvider>
  );
};

const mapStateToProps = (state: any) => {
  return {
    keycloakIdp: getKeycloakIdp(state),
    keycloakInitialized: getKeycloakInitialized(state),
  };
};

export default connect(mapStateToProps, {
  setKeycloakIdp,
  setKeycloakIdpHint,
  setKeycloakInitialized,
})(App);
